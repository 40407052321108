// @mixin font($font_name, $file_name, $weight, $style) {
//   font-style: #{$style};
//   font-weight: #{$weight};
//   font-family: $font_name;

//   font-display: swap;
//   src: url("../fonts/#{$file_name}.woff2") format("woff2"),
//   url("../fonts/#{$file_name}.woff") format("woff");
// }

@font-face {
  @include font("AGOptCyrillic", AGOptCyrillicNormal, 400, normal);
}

@font-face {
  @include font("AGOptCyrillic", AGOptCyrillicBold, 700, normal);
}

@font-face {
  @include font("AGOptCyrillic", AGOptCyrillicBold-Italic, 700, italic);
}

@font-face {
  @include font("AGOptCyrillic", AGOptCyrillicNormal-Italic, 400, italic);
}

@font-face {
  @include font("Adobe Garamond Pro", AGaramondPro-Regular, 400, normal);
}
