@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "AGOptCyrillic";
  font-display: swap;
  src: url("../fonts/AGOptCyrillicNormal.woff2") format("woff2"), url("../fonts/AGOptCyrillicNormal.woff") format("woff");
}
@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "AGOptCyrillic";
  font-display: swap;
  src: url("../fonts/AGOptCyrillicBold.woff2") format("woff2"), url("../fonts/AGOptCyrillicBold.woff") format("woff");
}
@font-face {
  font-style: italic;
  font-weight: 700;
  font-family: "AGOptCyrillic";
  font-display: swap;
  src: url("../fonts/AGOptCyrillicBold-Italic.woff2") format("woff2"), url("../fonts/AGOptCyrillicBold-Italic.woff") format("woff");
}
@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: "AGOptCyrillic";
  font-display: swap;
  src: url("../fonts/AGOptCyrillicNormal-Italic.woff2") format("woff2"), url("../fonts/AGOptCyrillicNormal-Italic.woff") format("woff");
}
@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Adobe Garamond Pro";
  font-display: swap;
  src: url("../fonts/AGaramondPro-Regular.woff2") format("woff2"), url("../fonts/AGaramondPro-Regular.woff") format("woff");
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  font-family: "AGOptCyrillic", "Arial", sans-serif;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

body {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}

a {
  display: inline-block;
  padding: 4px 0;
  color: #0a0a0a;
  text-decoration: none;
  user-select: none;
}
a[href]:hover, a[href]:focus {
  color: #2d966b;
  text-decoration: underline 2px solid #2d966b;
  outline: none;
  text-decoration-color: inherit;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}
a[href]:active {
  opacity: 0.6;
}

img,
video {
  display: block;
  max-width: 100%;
  border: 0;
  object-fit: cover;
  object-position: center;
}

textarea {
  resize: none;
}

input:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px #ffffff;
  -webkit-text-fill-color: #000000;
}

input,
textarea {
  border-radius: 0;
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
}
input:invalid,
textarea:invalid {
  box-shadow: none;
}

select {
  border-radius: 0;
}

input::-ms-clear, input::-ms-reveal {
  display: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type=number] {
  appearance: textfield;
}

select,
textarea,
input:matches([type=email],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=url]) {
  appearance: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  appearance: none;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));
}
.wrapper .header,
.wrapper .footer {
  flex-shrink: 0;
}
.wrapper main {
  flex-grow: 1;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.scroll-lock-ios {
  position: fixed;
  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.container {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 40px;
}
@media (max-width: 1023px) {
  .container {
    padding: 0 30px;
  }
}
@media (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}

.tex__container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}